
import { Vue, Component, Ref } from "vue-property-decorator";
import PageTitle from "@/components/common/PageTitle.vue";
import CourseListing from "@/modules/courses/components/CourseListing.vue";
import ContactForm from "@/modules/contact/components/ContactForm.vue";

@Component({
  components: { ContactForm, PageTitle, CourseListing },
})
export default class Contact extends Vue {}
