
import { FormRecord } from "@planetadeleste/vue-mc-martin-forms";
import { ValidationObserver } from "vee-validate";
import { Component, Ref, Vue } from "vue-property-decorator";
import LinkBtn from "@/components/common/LinkBtn.vue";

@Component({
  components: { LinkBtn },
})
export default class ContactForm extends Vue {
  obForm: FormRecord = new FormRecord();

  @Ref("observer") readonly obObserver!: InstanceType<
    typeof ValidationObserver
  >;

  get hasSlot(): boolean {
    return !this.$_.isEmpty(this.$slots);
  }

  async onSubmit(): Promise<void> {
    const valid = await this.obObserver.validate();
    if (!valid) {
      return;
    }

    const obResponse = await this.obForm.send();
    if (obResponse) {
      const obResponseData = obResponse.getData();
      if (obResponseData.status) {
        this.$toast.success(this.$t("form.contact.message.sent"));
        this.obForm = new FormRecord();
        this.obObserver.reset();
      } else if (obResponseData.message) {
        this.$toast.error(obResponseData.message);
      }
    }
  }
}
